module.exports = {
  title: "链接超时",
  description:
    "This invitation link is no longer valid. To activate your account, please follow these steps:",
  stepsToFollow: [
    "Click the button below.",
    `On the next page, click "Forgot password?"`,
    `Enter your email address and click "Continue."`,
    "You will receive an email with instructions on how to set up your password.",
  ],
  cta: "Continue",
  assistance_contact: "For any assistance, contact info@ayanarewards.com",
};
