module.exports = {
  'title': '创建账号',
  'title_invitation': '激活账户',
  'subtitle': '成为会员获取特别优惠',
  'placeholder_firstname': '名字',
  'placeholder_lastname': '姓氏',
  'placeholder_email': '邮件地址',
  'placeholder_password': '创造密码',
  'placeholder_nationality': '国籍',
  'placeholder_tc': '我已阅读并同意以下',
  'placeholder_tc_link': '条款和条件',
  'placeholder_subscribe': '我想接受阿雅娜会员优享计划的新闻和促销信息',
  'button_label': '注册',
  'title_modal_email_exist': '邮件已存在',
  'description_modal_email_exist': '您输入的邮件已存在于我们的数据库中。是否想要登录？',
  'btn_redirect_login': '登录',
  'btn_change_email': '更改邮件',
  'placeholder_selectbox': '请选择',
  'title_alert_message': 'Invitation',
  'btn_label_signin': '提交'
}